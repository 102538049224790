import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";


export default {


    methods: {
        addNewAsset(obj) {
            return new Promise((resolve, reject) => {
                const bodyFormData = new FormData();
                bodyFormData.append("asset_category_id", obj.asset_type);
                bodyFormData.append("asset_owner_id", obj.asset_owner);
                bodyFormData.append("asset_classification_id", obj.asset_classification);
                bodyFormData.append("asset_location[]", obj.asset_location);
                bodyFormData.append("asset_name", obj.asset_name);
                bodyFormData.append("asset_value", obj.asset_value);
                obj.selectedTags.map((tag,i)=>{
                    bodyFormData.append(`tags[${i}][is_new]`,`${tag.is_new?1:0}`)
                    if(tag.is_new===true){
                        bodyFormData.append(`tags[${i}][tag_title]`,`${tag.tag_title}`)
                    }
                    bodyFormData.append(`tags[${i}][tag_id]`,`${tag.tag_id?tag.tag_id:''}`)
                })
                obj.selectedSystemGroups.map((group,i)=>{
                    bodyFormData.append(`system_groups[${i}]`,group._id)
                })
                bodyFormData.append("asset_integrity", obj.asset_integrity ? 1 : 0);
                bodyFormData.append(
                    "asset_confidentiality",
                    obj.asset_confidentiality ? 1 : 0
                );
                bodyFormData.append(
                    "asset_availability",
                    obj.asset_availability ? 1 : 0
                );
                const options = {
                    method: "POST",
                    headers: { "content-type": "application/x-www-form-urlencoded" },
                    url: `${process.env.VUE_APP_BASEURL}/assets`,
                    data: bodyFormData,
                };
                this.$http(options)
                    .then((res) => {
                        resolve(res);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            })
        },
        getAssetDetails(assetId) {
            return new Promise((resolve, reject) => {
                const options = {
                    method: "GET",
                    headers: { "content-type": "application/x-www-form-urlencoded" },
                    url: `${process.env.VUE_APP_BASEURL}/assets/${assetId}`,
                };
                this.$http(options)
                    .then((res) => {
                        resolve(res)
                    })
                    .catch((err) => {
                        reject(err)
                    });
            })

        },
        getAssetTypes() {
            return new Promise((resolve, reject) => {
                const options = {
                    method: "GET",
                    headers: { "content-type": "application/x-www-form-urlencoded" },
                    url: `${process.env.VUE_APP_ONECSFURL}/ast_category/search`,
                };
                this.$http(options)
                    .then((res) => {
                        resolve(res)
                    })
                    .catch((err) => {
                        reject(err)
                    });
            })
        },
        getAssetClassifications() {
            return new Promise((resolve, reject) => {
                const options = {
                    method: "GET",
                    headers: { "content-type": "application/x-www-form-urlencoded" },
                    url: `${process.env.VUE_APP_BASEURL}/asset-classifications`,
                    params: { dont_paginate: true },
                };
                this.$http(options)
                    .then((res) => {
                        resolve(res)
                    })
                    .catch((err) => {
                        reject(err)
                    });
            })
        },
        getAssetOwners() {
            return new Promise((resolve, reject) => {
                const options = {
                    method: "GET",
                    headers: { "content-type": "application/x-www-form-urlencoded" },
                    url: `${process.env.VUE_APP_BASEURL}/users`,
                    params: { dont_paginate: true },
                };
                this.$http(options)
                    .then((res) => {
                        resolve(res);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            })
        },

        getAssetType(id) {
            return new Promise((resolve, reject) => {
                const options = {
                    method: "GET",
                    url: `${process.env.VUE_APP_ONECSFURL}/ast_categories/${id}`,
                };
                this.$http(options)
                    .then((res) => {
                        resolve(res)
                    })
                    .catch((err) => {
                        reject(err)
                    });
            })
        },

        getAssetClassification(id) {
            const options = {
                method: "GET",
                url: `${process.env.VUE_APP_BASEURL}/asset-classifications/` + id,
            };
            this.$http(options)
                .then((res) => {
                    this.assetDetails.assetClassification = res.data.data;
                })
                .catch((err) => {
                    console.log("err", err);
                });
        },

        deleteAssetWithId(id) {
            const options = {
                method: "DELETE",
                url: `${process.env.VUE_APP_BASEURL}/assets/` + id,
            };
            this.$http(options)
                .then((res) => {
                    console.log('del res', res)
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: "Asset Deleted Successfully",
                            icon: "EditIcon",
                            variant: "success",
                        },
                    });
                })
                .catch((err) => {
                    console.log("err", err);
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: "The Asset has not been Deleted",
                            icon: "EditIcon",
                            variant: "danger",
                        },
                    });
                });
        },

        getSystemGroupsBySearchPhrase(searchPhrase) {
            return new Promise((resolve, reject) => {
                const options = {
                    method: "GET",
                    url: `${process.env.VUE_APP_BASEURL}/system-groups`,
                    params: { limit: 5, search: searchPhrase },
                };
                this.$http(options)
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },

        addNewSystemGroup(systemGroup) {
            return new Promise((resolve, reject) => {
                const bodyFormData = new FormData();
                bodyFormData.append("title",systemGroup.title);
                bodyFormData.append("description",systemGroup.description);
                const options = {
                    method: "POST",
                    url: `${process.env.VUE_APP_BASEURL}/system-groups`,
                    data: bodyFormData
                };
                this.$http(options)
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },

        updateAssetRisk(assetId, riskId, obj){
            return new Promise((resolve, reject)=>{
                const bodyFormData = new FormData();
                bodyFormData.append("_method","PUT");
                if(obj.risk_statement){
                    bodyFormData.append("risk_statement",obj.risk_statement);
                }
                if(obj.threat_value){
                    bodyFormData.append("threat_value",obj.threat_value);
                }
                if(obj.vulnerability_value){
                    bodyFormData.append("vulnerability_value",obj.vulnerability_value);
                }
                if(obj.likelihood_value){
                    bodyFormData.append("likelihood_value",obj.likelihood_value);
                }
                if(obj.residual_threat_value){
                    bodyFormData.append("residual_threat_value",obj.residual_threat_value);
                }
                if(obj.residual_vulnerability_value){
                    bodyFormData.append("residual_vulnerability_value",obj.residual_vulnerability_value);
                }
                if(obj.residual_likelihood_value){
                    bodyFormData.append("residual_likelihood_value",obj.residual_likelihood_value);
                }
                if(obj.status){
                    bodyFormData.append("status",obj.status);
                }
                
                const options = {
                    method: "POST",
                    url: `${process.env.VUE_APP_BASEURL}/assets/${assetId}/risks/${riskId}/update`,
                    data: bodyFormData
                };
                this.$http(options)
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        }


    },
}