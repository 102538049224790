export default {
    name: "TagMixins",
    methods: {
        getAssetTagsBySearchPhrase(searchPhrase) {
            return new Promise((resolve, reject) => {
                const options = {
                    method: "GET",
                    url: `${process.env.VUE_APP_BASEURL}/tags`,
                    params: { limit: 5, search: searchPhrase },
                };
                this.$http(options)
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
    },
}